<mat-toolbar class="p-0 mat-elevation-z0 main-header-toolbar" *ngIf="!isKmpAppActive">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
            <div class="navbar-toggle-button-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md>
                <span class="async-count" *ngIf="!isDrawerOpen && asyncJobApiService.numberOfActiveAsyncJobs > 0"></span>
                <button mat-icon-button class="navbar-toggle-button" aria-label="Main menu" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>
            </div>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <a [routerLink]="'/grow'" aria-label="Logo" fxHide fxShow.gt-xs>
                        <img class="logo-icon" [src]="logoSrc" alt="Logo" />
                    </a>
                    <div class="sign" fxLayout="column" fxLayoutAlign="start">
                        <span class="tenant-label" aria-label="Tenant name">{{ tenantName }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-flat-button class="secondary ai-btn pr-8 pr-sm-16 mr-8" (click)="openRespeakWidget()" *ngIf="isRespeakWidgetEnabled">
                <mat-icon svgIcon="brain" class="mr-8"></mat-icon>
                <span class="text-gray-700" fxHide fxShow.gt-xs>{{ 'GENERAL.BUTTON.AI_ASSISTANT' | translate }}</span>
            </button>

            <button mat-button [matMenuTriggerFor]="userMenu" *ngIf="profile$ | async; let profile" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div class="relative">
                        <img *ngIf="profile.avatar?.src; else initials" class="avatar mr-0 mr-sm-16" [src]="profile.avatar.src" />
                        <ng-template #initials>
                            <div fxLayoutAlign="center center" class="avatar-initials avatar">{{ profile.fullName | initials }}</div>
                        </ng-template>
                        <ng-template [onlineStateIndicator]="!profile.hideOnlineState"> </ng-template>
                    </div>
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{ profile.firstName + ' ' + profile.lastName }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item attr.test-id="online-status" [matMenuTriggerFor]="onlineState" *ngIf="isUserOnlineStateEnabled">
                    <mat-icon>visibility</mat-icon>
                    <span>{{ 'GENERAL.LABEL.SET_STATUS' | translate }}</span>
                </button>

                <button mat-menu-item attr.test-id="profile" [routerLink]="'/profile'">
                    <mat-icon>account_circle</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.MY_PROFILE' | translate }}</span>
                </button>

                <button mat-menu-item attr.test-id="application-language" [routerLink]="'/profile/application-language'">
                    <mat-icon svgIcon="clarity_language"></mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.APPLICATION_LANGUAGE' | translate }}</span>
                </button>

                <button mat-menu-item attr.test-id="export-calendar" (click)="openDialog()">
                    <mat-icon>calendar_today</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.EXPORT_CALENDAR' | translate }}</span>
                </button>

                <button mat-menu-item attr.test-id="change-password" *ngIf="!isSSOFeatureEnabled" (click)="resetPassword()">
                    <mat-icon svgIcon="key"></mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.CHANGE_PASSWORD' | translate }}</span>
                </button>

                <button mat-menu-item attr.test-id="help" [matMenuTriggerFor]="help">
                    <mat-icon>help</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.HELP' | translate }}</span>
                </button>

                <button mat-menu-item attr.test-id="mobile-applications" [matMenuTriggerFor]="mobileAplications">
                    <mat-icon>phone_android</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.MOBILE_APPLICATIONS' | translate }}</span>
                </button>

                <a
                    mat-menu-item
                    attr.test-id="privacy-policy"
                    href="{{ 'https://www.maxbrain.com/' + (selectedLanguage.flag === 'gb' ? 'en' : selectedLanguage.flag) + '/datenschutzerklaerung' }}"
                    target="_blank"
                >
                    <mat-icon svgIcon="privacy_policy"></mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.PRIVACY_POLICY' | translate }}</span>
                </a>

                <!-- <a mat-menu-item href="https://www.maxbrain.com/en/terms-of-service" target="_blank">
                    <mat-icon>insert_drive_file</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.TERMS_OF_SERVICE' | translate }}</span>
                </a> -->

                <button mat-menu-item attr.test-id="logout" (click)="logout()" test-id="profilemenue-logout-button">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.LOGOUT' | translate }}</span>
                </button>
            </mat-menu>

            <mat-menu #help="matMenu" [overlapTrigger]="false">
                <a
                    mat-menu-item
                    attr.test-id="faq"
                    href="{{ 'https://www.maxbrain.com/' + (selectedLanguage.flag === 'gb' ? 'en' : selectedLanguage.flag) + '/support-center' }}"
                    target="_blank"
                >
                    <mat-icon>library_books</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.FAQ' | translate }}</span>
                </a>

                <a mat-menu-item attr.test-id="contact-support" [href]="'mailto:' + supportEmail">
                    <mat-icon>email</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.CONTACT_SUPPORT' | translate }}</span>
                </a>
            </mat-menu>

            <mat-menu #mobileAplications="matMenu" [overlapTrigger]="false">
                <a mat-menu-item attr.test-id="ios-application" [href]="iosUrl" target="_blank">
                    <mat-icon svgIcon="apple"></mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.IOS_APPLICATION' | translate }}</span>
                </a>

                <a mat-menu-item attr.test-id="android-application" [href]="androidUrl" target="_blank">
                    <mat-icon>android</mat-icon>
                    <span>{{ 'PROFILE_DROPDOWN.ANDROID_APPLICATION' | translate }}</span>
                </a>
            </mat-menu>

            <mat-menu #onlineState="matMenu" [overlapTrigger]="false">
                <a mat-menu-item attr.test-id="online" (click)="updateOnlineState('false')">
                    <div class="online-circle"></div>
                    <span>{{ 'GENERAL.LABEL.ONLINE' | translate }}</span>
                </a>

                <a mat-menu-item attr.test-id="offline" (click)="updateOnlineState('true')">
                    <div class="offline-circle"></div>
                    <span>{{ 'GENERAL.LABEL.APPEAR_OFFLINE' | translate }}</span>
                </a>
            </mat-menu>

            <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button fxHide.gt-md
                    class="chat-panel-toggle-button"
                    (click)="toggleSidebarOpen('chatPanel')"
                    aria-label="Toggle chat panel">
                <mat-icon class="secondary-text">chat</mat-icon>
            </button>

            <div class="toolbar-separator" fxHide.gt-md></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
